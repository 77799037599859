export default function hideOrShowPassword(actionButton, passwordField) {
  actionButton.on('click', () => {
    if (passwordField.attr('type') === 'password') {
        passwordField.attr('type', 'text');
        actionButton.removeClass("bi-eye-slash");
        actionButton.addClass("bi-eye");
    } else {
      passwordField.attr('type', 'password');
      actionButton.removeClass("bi-eye");
      actionButton.addClass("bi-eye-slash");
    }
  })
}
