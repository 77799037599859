import hideOrShowPassword from "../hide_show_password";

$(document).ready(function () {
  const password =  $('#user_password');
  const passwordIcon = $('#password_eye_icon');

  if ($(".alert").length > 0) {
    var invalid_div = document.getElementById("login-invalid")
    var alert_message = document.getElementsByClassName("alert")[0].textContent
    invalid_div.innerHTML = alert_message
    invalid_div.style.display = "inline"
  }

  hideOrShowPassword(passwordIcon, password);

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('inactive_user') == 'true') {
    $('#inactiveUserModal').modal('show');

    $('#inactiveUserModal').on('hidden.bs.modal', function() {
      window.history.replaceState(null, '', window.location.pathname);
    });

    $('#contactUsButton').on('click', function() {
      window.history.replaceState(null, '', window.location.pathname);
    });
  }
});
